import z from 'zod';

export const roleSchema = z.union([
	z.literal('ROLE_CABINET_MANAGER'),
	z.literal('ROLE_CABINET_USER'),
	z.literal('ROLE_CABINET_MARKETING'),
]);

export const loginCredentialsSchema = z.object({
	login: z.string(),
	password: z.string(),
});

export const loginResponseTokensSchema = z.object({
	exp: z.number(),
	iat: z.number(),
	login: z.string(),
	needChangePassword: z.boolean(),
	roles: z.array(roleSchema),
});

export const loginErrorResponseSchema = z.object({
	message: z.string(),
});
