import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';

import { type TReportTableProps } from '@shared/components/report-table/types';
import { getBaseColumnProps } from '@shared/components/report-table/utils/get-base-column-props';
import { TableLoadingState } from '@shared/components/report-table/components/table-loading-state';
import { TableErrorState } from '@shared/components/report-table/components/table-error-state';
import { TableEmptyState } from '@shared/components/report-table/components/table-empty-state';
import { SortingButton } from '@shared/components/report-table/components/sorting-button';

import {
	ReportTable,
	ReportTableBody,
	ReportTableHead,
	ReportTablePaper,
	ReportTableRowData,
	ReportTableRowNames,
	ReportTableRowTotals,
	TableCellContent,
	TableCellContentNames,
} from './styles';

export const CommonReportTable = <ColumnId extends string>({
	columns,
	data,
	totals,
	isLoading,
	isError,
	page,
	rowsPerPage,
	sorting,
	onSortingChange,
	invalidate,
	isEmpty,
}: TReportTableProps<ColumnId>) => {
	// todo Add backend pagination later
	const pageRowData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const isPageRowDataEmpty = !pageRowData.length;

	const columnNamesCells = columns.map((column) => (
		<TableCell key={column.id} {...getBaseColumnProps(column)}>
			<TableCellContentNames $align={column.align}>
				<span>{column.label}</span>

				{column.needSorting && (
					<SortingButton
						sortingOrder={sorting[column.id]}
						onClick={() => onSortingChange(column.id)}
					/>
				)}
			</TableCellContentNames>
		</TableCell>
	));

	const totalsCells = columns.map((column) => {
		if (!totals) {
			return [];
		}

		return (
			<TableCell key={column.id} {...getBaseColumnProps(column)}>
				{totals.cells[column.id].value}
			</TableCell>
		);
	});

	let dataCells: React.ReactNode;

	if (isEmpty) {
		dataCells = <TableEmptyState columns={columns} />;
	} else if (isLoading) {
		dataCells = <TableLoadingState columns={columns} />;
	} else if (isError) {
		dataCells = <TableErrorState columns={columns} invalidate={invalidate} />;
	} else if (isPageRowDataEmpty) {
		dataCells = <TableEmptyState columns={columns} />;
	} else {
		dataCells = pageRowData.map((rowData) => (
			<ReportTableRowData hover tabIndex={-1} key={rowData.id} $isWeekend={rowData.isWeekend}>
				{columns.map((column) => (
					<TableCell key={column.id} {...getBaseColumnProps(column)}>
						<TableCellContent $align={column.align}>
							{rowData.cells[column.id].render?.(rowData.cells[column.id].value) ??
								rowData.cells[column.id].value}
						</TableCellContent>
					</TableCell>
				))}
			</ReportTableRowData>
		));
	}

	return (
		<ReportTablePaper>
			<TableContainer>
				<ReportTable>
					<ReportTableHead>
						<ReportTableRowNames>{columnNamesCells}</ReportTableRowNames>

						{!isError && !isPageRowDataEmpty && (
							<ReportTableRowTotals>{totalsCells}</ReportTableRowTotals>
						)}
					</ReportTableHead>

					<ReportTableBody>{dataCells}</ReportTableBody>
				</ReportTable>
			</TableContainer>
		</ReportTablePaper>
	);
};
